import React from "react";
import Image from "gatsby-image";
import { Link, graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

// Content from https://www.privacypolicygenerator.info/

function PrivacyPolicy() {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <section>
        <div className="container mx-auto my-24">
          <h1 className="text-4xl uppercase font-bold mb-8 text-center">
            Privacy Policy
          </h1>

          <p className="mb-2">
            At Ostrom Contracting, website homepage accessible{" "}
            <a className="underline" href="https://www.ostromcontracting.com">
              here
            </a>
            , one of our main priorities is the privacy of our visitors. This
            Privacy Policy document contains types of information that is
            collected and recorded by Ostrom Contracting and how we use it.
          </p>

          <p className="mb-2">
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to{" "}
            <a className="underline" href="/contact">
              contact us
            </a>
            .
          </p>

          <p className="mb-8">
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in Ostrom Contracting. This policy
            is not applicable to any information collected offline or via
            channels other than this website.
          </p>

          <h2 className="font-bold text-xl mb-1">Consent</h2>

          <p className="mb-8">
            By using our website, you hereby consent to our Privacy Policy and
            agree to its terms. If you do not wish to be bound by this Privacy
            Policy, please exit the site now.
          </p>

          <h2 className="font-bold text-xl mb-1">Information we collect</h2>

          <p className="mb-2">
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
          </p>
          <p className="mb-8">
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide.
          </p>

          <h2 className="font-bold text-xl mb-1">
            How we use your information
          </h2>

          <p className="mb-2">
            We use the information we collect in various ways, including to:
          </p>

          <ul className="mb-8">
            <li className="mb-2 pl-2">
              - Provide, operate, and maintain our website
            </li>
            <li className="mb-2 pl-2">
              - Improve, personalize, and expand our website
            </li>
            <li className="mb-2 pl-2">
              - Understand and analyze how you use our website
            </li>
            <li className="mb-2 pl-2">
              - Develop new services, features, and functionality
            </li>
            <li className="mb-2 pl-2">
              - Communicate with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the website, and for
              marketing and promotional purposes
            </li>
            <li className="mb-2 pl-2">- Send you emails</li>
            <li className="mb-2 pl-2">- Find and prevent fraud</li>
          </ul>

          <h2 className="font-bold text-xl mb-1">Log Files</h2>

          <p className="mb-8">
            Ostrom Contracting follows a standard procedure of using log files.
            These files log visitors when they visit websites. All hosting
            companies do this and a part of hosting services' analytics. The
            information collected by log files include internet protocol (IP)
            addresses, browser type, Internet Service Provider (ISP), date and
            time stamp, referring/exit pages, and possibly the number of clicks.
            These are not linked to any information that is personally
            identifiable. The purpose of the information is for analyzing
            trends, administering the site, tracking users' movement on the
            website, and gathering demographic information.
          </p>

          <h2 className="font-bold text-xl mb-1">Cookies and Web Beacons</h2>

          <p className="mb-2">
            Like any other website, Ostrom Contracting uses 'cookies'. These
            cookies are used to store information including visitors'
            preferences, and the pages on the website that the visitor accessed
            or visited. The information is used to optimize the users'
            experience by customizing our web page content based on visitors'
            browser type and/or other information.
          </p>

          <p className="mb-8">
            For more general information on cookies, please read{" "}
            <a
              className="underline"
              href="https://www.cookieconsent.com/what-are-cookies/"
            >
              "What Are Cookies" from Cookie Consent
            </a>
            .
          </p>

          <h2 className="font-bold text-xl mb-1">
            Third Party Privacy Policies
          </h2>

          <p className="mb-2">
            This webiste contains links to other websites and Ostrom
            Contracting's Privacy Policy does not apply to other websites. Thus,
            we are advising you to consult the respective Privacy Policies of
            these third-party ad servers for more detailed information. It may
            include their practices and instructions about how to opt-out of
            certain options.{" "}
          </p>

          <p className="mb-8">
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found at the browsers'
            respective websites.
          </p>

          <h2 className="font-bold text-xl mb-1">
            CCPA Privacy Rights (Do Not Sell My Personal Information)
          </h2>

          <p className="mb-2">
            Under the CCPA, among other rights, California consumers have the
            right to:
          </p>
          <p className="mb-2">
            Request that a business that collects a consumer's personal data
            disclose the categories and specific pieces of personal data that a
            business has collected about consumers.
          </p>
          <p className="mb-2">
            Request that a business delete any personal data about the consumer
            that a business has collected.
          </p>
          <p className="mb-2">
            Request that a business that sells a consumer's personal data, not
            sell the consumer's personal data.
          </p>
          <p className="mb-8">
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please{" "}
            <a className="underline" href="/contact">
              contact us
            </a>
            .
          </p>

          <h2 className="font-bold text-xl mb-1">
            GDPR Data Protection Rights
          </h2>

          <p className="mb-2">
            We would like to make sure you are fully aware of all of your data
            protection rights. Every user is entitled to the following:
          </p>
          <p className="mb-2">
            The right to access – You have the right to request copies of your
            personal data. We may charge you a small fee for this service.
          </p>
          <p className="mb-2">
            The right to rectification – You have the right to request that we
            correct any information you believe is inaccurate. You also have the
            right to request that we complete the information you believe is
            incomplete.
          </p>
          <p className="mb-2">
            The right to erasure – You have the right to request that we erase
            your personal data, under certain conditions.
          </p>
          <p className="mb-2">
            The right to restrict processing – You have the right to request
            that we restrict the processing of your personal data, under certain
            conditions.
          </p>
          <p className="mb-2">
            The right to object to processing – You have the right to object to
            our processing of your personal data, under certain conditions.
          </p>
          <p className="mb-2">
            The right to data portability – You have the right to request that
            we transfer the data that we have collected to another organization,
            or directly to you, under certain conditions.
          </p>
          <p className="mb-8">
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </p>

          <h2 className="font-bold text-xl mb-1">Children's Information</h2>

          <p className="mb-2">
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
          </p>

          <p className="mb-2">
            Ostrom Contracting does not knowingly collect any Personal
            Identifiable Information from children under the age of 13. If you
            think that your child provided this kind of information on our
            website, we strongly encourage you to contact us immediately and we
            will do our best efforts to promptly remove such information from
            our records.
          </p>

          <h2 className="font-bold text-xl mb-1">No Guarantees</h2>

          <p className="mb-8">
            While this Privacy Policy states our standards for maintenance of
            data and our efforts to meet them, we are not in a position to
            guarantee these standards. For example, there may be factors beyond
            our control that may result in disclosure of data. As a consequence,
            we disclaim any warranties or representations, express, written or
            implied, relating to maintenance or nondisclosure of any and all
            data on this site.
          </p>

          <h2 className="font-bold text-xl mb-1">Contact Us</h2>

          <p className="mb-8">
            If any user or visitor of this site suspects Ostrom Contracting has
            handled any information in a manner that does not comply with this
            Privacy Policy, please{" "}
            <a className="underline" href="/contact">
              contact us
            </a>
            . If you have any questions regarding this Privacy Policy, please{" "}
            <a className="underline" href="/contact">
              contact us
            </a>
            .
          </p>
        </div>
      </section>
    </Layout>
  );
}

export default PrivacyPolicy;
